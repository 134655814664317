// ============================
//     Progress css start
// ============================

.progress {
  overflow: visible;

  .progress-bar {
    border-radius: $progress-border-radius;
  }
}
// Progress css end
