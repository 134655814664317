ng-select.ng-select > div {
  border: none;
  > div.single {
    background-color: $info;
    color: #fff;
    padding: 8px 30px 8px 20px;
    border-radius: 4px;
    height: auto;
  }
  &.disabled > div.single {
    background-color: #aaa;
    color: #fff;
  }
  > div.single > div {
    &.clear:hover,
    &.toggle:hover {
      background-color: transparent;
    }
    &.clear {
      color: #fff;
    }
    &.toggle {
      color: #fff;
      font-size: 9px;
    }
    &.clear,
    &.toggle {
      width: 0;
    }
  }
}

.ng-select select-dropdown .highlighted {
  background-color: $info;
}

.famfamfam-flags {
  display: inline-block;
  margin-right: 6px;
  width: 16px;
}

ng-select.ng-select > div > div {
  &.multiple {
    border: 1px solid #aaa;
    padding: 6px 10px 6px 10px !important;
    border-radius: 4px;
    > div.option {
      span.deselect-option {
        color: #fff !important;
        font-size: 10px !important;
        vertical-align: middle;
        font-weight: bold;
        margin-right: 2px;
      }
      background-color: $info !important;
      border: 1px solid $info !important;
      padding: 5px 15px !important;
      color: #fff !important;
      margin: 0 2px 2px !important;
    }
  }
  &.single > div {
    &.placeholder {
      color: #fff;
    }
    &.clear {
      font-size: 10px;
      margin-right: 14px;
      font-weight: bold;
    }
  }
}
