@import '../../settings/custom-variables';
$menu-styler: 280px;

/**  =====================
      Custom css start
==========================  **/
body.berry-rtl {
  direction: rtl;
  text-align: right;
  position: unset;

  .scroll-div > .scroll-element.scroll-y {
    right: auto;
    left: 6px;
  }

  // ======    menu   ==========//
  // ======    Menu Styler Start     ========//
  $style-block-width: 279px;

  .menu-styler {
    h6 {
      &:after {
        right: 0;
        left: auto;
      }
    }

    .pct-c-btn {
      > a {
        right: auto;
        left: 0;

        &:before {
          content: '\62';
          right: auto;
          transform: rotate(0deg);
          left: -45px;
          text-shadow:
            1px 0 8px transparentize($primary, 0.1),
            6px 0 8px rgba(0, 0, 0, 0.1);
        }

        &:after {
          right: auto;
          left: 4px;
        }
      }
    }

    &.open {
      .pct-c-btn {
        > a {
          right: auto;
          left: $style-block-width;

          &:before {
            right: auto;
          }

          &:after {
            content: '\e849';
            right: auto;
          }
        }
      }

      .style-block {
        right: auto;
        left: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }
    }

    .style-block {
      right: auto;
      left: -#{$style-block-width};
    }

    .theme-color {
      > a {
        margin-right: 0;
        margin-left: 5px;

        span {
          &:before {
            left: auto;
            right: 0;
          }

          &:after {
            right: auto;
            left: 0;
          }
        }

        &.active {
          &:before {
            // content: "\e83f";
            left: auto;
            right: 14px;
          }

          &:after {
            left: auto;
            right: 0;
          }
        }
      }

      &.small {
        > a {
          &.active {
            &:before {
              right: 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .menu-styler {
      &.open {
        .pct-c-btn {
          > a {
            left: 280px;
          }
        }
      }
    }
  }
  // ======    Menu Styler end     ========//

  .b-brand .b-title {
    margin-left: 0;
    margin-right: 10px;
  }

  .coded-navbar {
    direction: rtl;
    text-align: right;

    ul {
      // padding-left: 0;
      padding-right: 0;
    }

    .mobile-menu {
      right: auto;
      left: 10px;
    }

    .coded-badge {
      right: auto;
      left: 35px;
    }

    &.compact {
      .coded-inner-navbar {
        li {
          &.coded-hasmenu {
            > a:after {
              left: -4px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .coded-inner-navbar {
      li {
        > a {
          text-align: right;
        }

        &.coded-hasmenu {
          > a:after {
            right: auto;
            left: 20px;
            transform: rotate(180deg);
          }

          .coded-submenu {
            li {
              > a {
                text-align: right;
                padding: 10px 75px 10px 30px;

                &:before {
                  left: auto;
                  right: 55px;
                  top: 18px;
                }
              }

              .coded-submenu {
                li {
                  a {
                    padding: 10px 95px 10px 30px;

                    &:before {
                      left: auto;
                      right: 75px;
                      top: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      > li {
        a {
          .coded-micon {
            margin-right: 0;
            margin-left: 7px;
          }
        }

        &.active,
        &.coded-trigger {
          &:after {
            left: auto;
            right: 0;
          }
        }
      }
    }

    &.navbar-collapsed {
      .header-logo {
        .logo-thumb {
          left: auto;
          right: calc((#{$Menu-collapsed-width} / 2) - 20px);
        }
      }

      .coded-menu-caption {
        &:after {
          left: auto;
          right: 15px;
        }
      }

      ~ .coded-header,
      ~ .coded-main-container {
        margin-left: auto;
        margin-right: $Menu-collapsed-width;
      }

      &:hover {
        .header-logo {
          .mobile-menu {
            right: auto;
            left: 0;
          }
        }
      }

      .coded-inner-navbar > li.coded-hasmenu > a:after {
        left: 12px;
        right: auto;
      }
    }

    &.mob-open,
    &.navbar-collapsed:hover {
      ~ .coded-header,
      ~ .coded-main-container {
        margin-left: auto;
        margin-right: $Menu-collapsed-width;

        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .coded-main-container {
    margin-left: 0;
    margin-right: $Menu-width;
  }
  // ======    menu   ==========//

  // ====== Radio & checkbox ======///
  .radio {
    margin-right: 0;
    margin-left: 5px;

    input[type='radio'] {
      + .cr {
        padding-right: 0;

        &:after,
        &:before {
          margin-right: 0;
          margin-left: 10px;
        }

        &:after {
          left: auto;
          right: 4px;
        }
      }
    }

    &.radio-fill {
      input[type='radio'] {
        + .cr {
          &:after {
            left: auto;
            right: 4px;
          }
        }
      }
    }
    &.radio-infill {
      input[type='radio'] {
        + .cr {
          &:after {
            left: auto;
            right: 28px;
          }
        }
      }
    }
  }

  .checkbox {
    margin-right: 0;
    margin-left: 10px;

    input[type='checkbox'] {
      + .cr {
        padding-right: 0;

        &:before {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }

    &.checkbox-fill {
      input[type='checkbox'] {
        + .cr {
          &:after {
            margin-right: 0;
            margin-left: 10px;
            left: auto;
            right: 3px;
          }
        }
      }
    }

    &.checkbox-fill-1 {
      input[type='checkbox'] {
        + .cr {
          &:after {
            margin-right: 0;
            margin-left: 10px;
            left: auto;
            right: 23px;
          }
        }
      }
    }
  }

  // ======= Scrollbar =====///
  .ng-scrollbar-wrapper {
    direction: rtl;
    text-align: right;
  }

  // ============================
  //     RTL css start
  // ============================

  // utility.
  $i: 0;
  @while $i<=50 {
    .p {
      &-l-#{$i} {
        padding-right: #{$i}px;
        padding-left: 0;
        @if ($i == 0) {
          &[class*='col'] {
            padding-left: 15px;
          }
        }
      }

      &-r-#{$i} {
        padding-left: #{$i}px;
        padding-right: 0;
        @if ($i == 0) {
          &[class*='col'] {
            padding-right: 15px;
          }
        }
      }
    }

    .m {
      &-l-#{$i} {
        margin-right: #{$i}px;
        margin-left: 0;
      }

      &-r-#{$i} {
        margin-left: #{$i}px;
        margin-right: 0;
      }
    }
    $i: $i + 5;
  }
  ol:not([class]),
  ul:not([class]) {
    padding-left: 0;
    padding-right: 2rem;
  }
  dl,
  .list-inline,
  .list-group,
  .list-unstyled {
    padding-right: 0;
    padding-left: 0;
  }

  .list-inline-item:not(:last-child) {
    margin-left: 0.5rem;
  }
  dd {
    margin-right: 0;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: right;
  }
  .dropdown-menu {
    text-align: right;
  }
  .dropdown .dropdown-item i {
    margin-right: 0;
    margin-left: 10px;
  }
  .alert-dismissible {
    padding-right: $alert-padding-x;
    padding-left: 3.75rem;
    .btn-close {
      right: auto;
      left: 0;
    }
  }
  .accordion-button::after {
    margin-right: auto;
    margin-left: 0;
  }
  .pc-container {
    margin-right: 260px;
    margin-left: 20px;
    @media (max-width: 1024px) {
      margin-right: 20px !important;
    }
  }
  .coded-navbar.compact {
    ~ .pc-container {
      margin-right: $sidebar-compact-width;
      margin-left: 20px;
    }
    .m-header a {
      direction: ltr;
    }

    &.navbar-collapsed {
      ~ .pc-container {
        margin-right: 20px;
      }
    }
  }

  .pc-header .header-wrapper {
    margin: 0px;
    .me-0 {
      margin: 0 16px 0 0 !important;
    }
    .ms-0 {
      margin: 0 0 0 16px !important;
    }
  }

  .coded-navbar.navbar-collapsed {
    ~ .pc-container,
    ~ .pc-footer {
      margin-right: 20px;
    }
  }

  .coded-navbar {
    @media (min-width: 1025px) {
      &.pc-sidebar-hide {
        transform: translateX(260px);
        ~ .pc-container {
          margin-right: 20px;
        }
      }
    }
    @media (max-width: 1024px) {
      left: auto;
      right: -#{$sidebar-width};
      transition: all 0.15s ease-in-out;
      &.mob-open {
        right: 0;
      }
    }
  }
  .pc-header {
    .m-header {
      padding: 16px 24px 16px 10px;
    }
    .user-avtar {
      margin-right: 0;
      margin-left: 10px;
    }
    .header-search {
      .icon-search {
        left: auto;
        right: 15px;
      }
      .btn-search {
        right: auto;
        left: 9px;
      }
    }
    .pc-h-dropdown.dropdown-menu-end {
      right: auto !important;
      left: 0 !important;
    }
    .pc-mega-list {
      padding-right: 0;
    }
    .pc-mega-menu .pc-mega-dmenu {
      left: 40px !important;
      right: calc(#{$sidebar-width} + 20px) !important;
    }
    .dropdown-user-profile .upgradeplan-block {
      &::before {
        right: auto;
        left: -70px;
      }
      &::after {
        right: auto;
        left: -150px;
      }
    }
    .dropdown-user-profile .settings-block .form-switch {
      padding-left: 2.5em;
      .form-check-input {
        float: left;
      }
    }
    .dropdown-user-profile {
      .dropdown-item {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: 575.98px) {
      .header-user-profile .pc-head-link .user-avtar {
        margin-left: 0;
      }
      .pc-h-item .pc-h-dropdown {
        right: 0 !important;
      }
      .pc-mega-menu .pc-mega-dmenu {
        left: 0 !important;
      }
    }
  }
  .page-header {
    h5,
    .h5 {
      border-left: 1px solid darken($body-bg, 10%);
      border-right: none;
      margin-right: 0;
      padding-right: 0;
      margin-left: 8px;
      padding-left: 8px;
    }
  }
  .pc-footer {
    margin-left: 20px;
    margin-right: $sidebar-width;
    @media (max-width: 1024px) {
      margin-right: 20px;
    }
  }
  .dashnum-card {
    .round {
      &.small {
        right: auto;
        left: -15px;
      }
      &.big {
        right: auto;
        left: -95px;
      }
    }
    &.dashnum-card-small {
      .round {
        &.small {
          right: auto;
          left: -130px;
        }
        &.big {
          right: auto;
          left: -180px;
        }
      }
    }
  }
  .order-card {
    .card-icon {
      right: auto;
      left: 14px;
    }
  }
  .social-widget-card i {
    right: auto;
    left: 14px;
  }
  .user-activity-card .u-img .profile-img {
    right: auto;
    left: -10px;
  }
  .latest-update-card .card-body .latest-update-box:after {
    right: 82px;
    left: auto;
  }
  .task-card {
    li {
      padding-left: 0;
      padding-right: 30px;
      .task-icon {
        right: 3px;
        left: auto;
      }
    }
    .task-list {
      &:after {
        right: 10px;
        left: auto;
      }
      &:before {
        right: 3px;
        left: auto;
      }
    }
  }
  .new-cust-card .align-middle .status {
    left: 0;
    right: auto;
  }
  .apexcharts-legend-marker {
    margin-left: 3px;
  }
  .btn-group {
    .btn {
      border-radius: var(--bs-btn-border-radius) !important;
      &:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
      &:not(:first-child) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
  .page-item {
    &:not(:first-child) .page-link {
      margin-left: 0;
      margin-right: -1px;
    }
    &:last-child .page-link {
      border-top-left-radius: var(--bs-pagination-border-radius);
      border-bottom-left-radius: var(--bs-pagination-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:first-child .page-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--bs-pagination-border-radius);
      border-bottom-right-radius: var(--bs-pagination-border-radius);
    }
  }
  .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
  }
  .modal-header .btn-close {
    margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5)
      calc(var(--bs-modal-header-padding-y) * -0.5);
  }
  .toast-header .btn-close {
    margin-right: var(--bs-toast-padding-x);
    margin-left: calc(var(--bs-toast-padding-x) * -0.5);
  }
  .notifier-container {
    right: auto;
    left: 4px;
    .notifier:not(.shown) {
      transform: translateX(-100%);
    }
  }
  .vtree,
  pre[class*='language-'] > code {
    direction: ltr;
    text-align: left;
  }
  select.form-control,
  select.custom-select,
  select.dataTable-selector,
  select.dataTable-input,
  .form-select {
    background-position: left $form-select-padding-x center;
  }
  .form-select {
    padding-right: $input-padding-x;
    padding-left: $form-select-indicator-padding;
  }
  .form-check {
    padding-left: 0;
    padding-right: $form-check-padding-start;
    .form-check-input {
      float: right;
      margin-right: $form-check-padding-start * -1;
    }
  }

  .form-switch {
    padding-left: 0;
    padding-right: $form-switch-padding-start;

    .form-check-input {
      margin-right: $form-switch-padding-start * -1;
      background-position: right center;
      &:checked {
        background-position: left center;
      }
    }
  }
  .custom-switch-v1.form-switch {
    padding-right: 2.9em;
    .form-check-input {
      margin-right: -2.9em;
    }
  }
  .input-group {
    > * {
      border-radius: $input-border-radius !important;
    }
    &:not(.has-validation) {
      > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
      > .dropdown-toggle:nth-last-child(n + 3),
      > .form-floating:not(:last-child) > .form-control,
      > .form-floating:not(:last-child) > .form-select {
        // @include border-start-radius(0);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    $validation-messages: '';
    @each $state in map-keys($form-validation-states) {
      $validation-messages: $validation-messages + ':not(.' + unquote($state) + '-tooltip)' + ':not(.' + unquote($state) + '-feedback)';
    }
    > :not(:first-child):not(.dropdown-menu):not(.form-floating)#{$validation-messages},
    > .form-floating:not(:first-child) > .form-control,
    > .form-floating:not(:first-child) > .form-select {
      margin-right: -$input-border-width;
      margin-left: 0;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .form-floating > label {
    left: auto;
    right: 0;
  }
  .form-check-inline {
    margin-right: 0;
    margin-left: 1rem;
  }
  .choices[data-type*='select-multiple'] .choices__button,
  .choices[data-type*='text'] .choices__button {
    margin: 0 8px 0 -4px;
    border-left: none;
    border-right: 1px solid rgba(255, 255, 255, 0.35);
  }
  .choices[data-type*='select-one']:after {
    right: auto;
    left: 11.5px;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: auto;
    left: 0;
  }
  .editor-toolbar,
  .CodeMirror {
    direction: ltr;
    text-align: left;
  }
  .dataTable-sorter::before,
  .dataTable-sorter::after {
    right: auto;
    left: 4px;
  }

  .table.dataTable[class*='table-'] thead th {
    text-align: justify;
  }
  .dataTable-dropdown label select {
    margin-right: 0;
    margin-left: 8px;
  }
  .dataTables_length select.form-select {
    padding-right: 0.7rem;
    padding-left: 30px;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    margin-right: 0.5em;
  }
  div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
  }
  table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
  table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
    padding-left: 0.75rem;
    padding-right: 30px;
  }
  table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
    left: auto;
    right: 5px;
  }
  .pc-icon-checkbox {
    padding-right: 0;
  }
  .mail-wrapper {
    .mail-menulist {
      margin-right: 0;
      margin-left: var(--bs-gutter-x);
    }
  }
  .mail-option .mail-buttons {
    right: auto;
    left: 5px;
    transform-origin: left;
  }
  .nav {
    padding-right: 0;
  }
  .chat-wrapper {
    .chat-userlist {
      margin-right: 0;
      margin-left: var(--bs-gutter-x);
    }
    .chat-userinfo {
      margin-right: var(--bs-gutter-x);
      margin-left: 0;
    }
    .media {
      direction: rtl;
    }
  }

  .ecom-wrapper {
    .ecom-filter {
      margin-right: var(--bs-gutter-x);
      margin-left: 0;
    }
    .ecom-offcanvas.show .ecom-filter {
      margin-right: 0;
    }
  }

  .table-card .card-body,
  .table-body.card-body {
    .table {
      tr {
        td,
        th {
          &:first-child {
            padding-right: 25px;
            padding-left: 0.75rem;
          }

          &:last-child {
            padding-right: 25px;
            padding-left: 0.75rem;
          }
        }
      }
    }
  }

  .order-timeline {
    @include media-breakpoint-down(md) {
      .timeline-progress {
        &:after,
        &:before {
          transform: translate(50%);
          left: auto;
          right: 25px;
        }
      }
    }
  }

  .mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
    margin-left: 0;
    margin-left: 8px;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size='1'],
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size='1'],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size='1'],
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size='1'] {
    background-position:
      left 0.75rem center,
      center left 2.75rem;
  }
  .dataTable-table th a {
    margin-left: 16px;
    padding-right: 0;
  }
  .auth-main .auth-wrapper .auth-form img {
    padding-left: 15px;
    padding-right: 0;
  }

  // RTL css end
}

/**  =====================
      angular start
==========================  **/
body.berry-rtl {
  .coded-navbar .coded-inner-navbar > app-nav-group > app-nav-collapse > li.active:after {
    left: auto;
    right: 0;
  }

  .coded-navbar .coded-inner-navbar > app-nav-group > app-nav-collapse > li.coded-trigger > ul:before {
    left: auto;
    right: 40px;
  }
  .coded-navbar.navbar-collapsed .coded-inner-navbar app-nav-item > li.coded-hasmenu > a:after,
  .coded-navbar.navbar-collapsed .coded-inner-navbar > app-nav-group > app-nav-collapse > li.coded-hasmenu > a:after {
    right: auto;
    left: 12px;
  }
  .coded-navbar {
    .coded-inner-navbar li > a > .coded-micon {
      margin-right: 0;
      margin-left: 15px;
      float: right;
    }
    &.compact {
      .coded-inner-navbar li > a > .coded-micon {
        margin-left: 0px;
        float: none;
      }
    }
  }
}
