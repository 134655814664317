/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/**======================================================================
=========================================================================
Template Name: berry - Most Complete Bootstrap Admin Template
Author: CodedThemes
Support: support@CodedThemes.net
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import 'scss/settings/preset-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

$pc-body-bg: map-get($preset-colors, 'preset-1', 'body');
$pc-blue: map-get($preset-colors, 'preset-1', 'primary');
$pc-secondary: map-get($preset-colors, 'preset-1', 'secondary');
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';
@import 'scss/settings/general';
@import 'scss/themes/bootstrap';

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'scss/themes/generic';
@import 'scss/themes/general';

// Layouts
@import 'scss/themes/layouts/layouts';
@import 'scss/themes/layout/layout';

// widget
@import 'scss/themes/components/widget';

// Components
@import 'scss/themes/components/components';

// pages
@import 'scss/themes/pages/page';

// custom plugins
@import 'scss/themes/plugins/plugins';

//  other
@import 'scss/themes/other/other';
@import 'scss/style-preset.scss';

// fonts
@import url('scss/fonts/berry/berry-icons.css'); /** custom font icons - berry **/
@import 'scss/fonts/feather.css';
@import 'scss/fonts/fontawesome.css';
@import 'scss/fonts/material.css';
@import 'scss/fonts/tabler-icons.min.css';
@import 'scss/fonts//phosphor/duotone/style.css';

// extra module SCSS

// angular Material css
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Form Wizard module
@import '../node_modules/angular-archwizard/styles/archwizard';

// calender css
@import '../node_modules/angular-calendar/css/angular-calendar.css';

// text-editor
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';

@import 'scss/themes/pages//text-editor';

// third party
@import '../node_modules/angular-notifier/styles';

// ng-select
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@ng-select/ng-select/themes/ant.design.theme.css';

// rtl mode Css
.berry-rtl {
  @import 'scss/themes/layout/style-rtl-utility';
  @import 'node_modules/bootstrap/scss/utilities/api';
}
