table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1.05rem 0.75rem;
}
.dataTables_length,
.dataTables_filter {
  label {
    display: flex;
    align-items: center;
    select,
    input {
      background: $theme-background;
      padding: 10px 20px;
      border: 1px solid #ced4da;
      margin: 0 10px;
      border-radius: 0.2rem;

      &:focus {
        box-shadow: none;
        outline: none;
        background: $theme-background;
      }

      &::-moz-placeholder {
        color: #aaaeb3;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #aaaeb3;
      }

      &::-webkit-input-placeholder {
        color: #aaaeb3;
      }
    }
  }
}

.dataTables_wrapper {
  .dt-buttons {
    display: inline-flex;
    vertical-align: middle;
    margin-bottom: 5px;
  }
  .dt-button {
    background: $primary;
    border: 1px solid $primary;
    color: #fff;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    + .dt-button {
      margin-left: -1px;
    }
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $secondary;
      color: #fff;
      box-shadow: none;
    }

    &.active {
      background: $secondary;
      color: #fff;
      border: 1px solid $secondary;
      box-shadow: none;
    }
  }
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #dceff7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  // color: $primary-color !important;
  border: 1px solid $primary !important;
  background-color: $primary !important;
  color: #fff;
  cursor: default;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: shift-color($primary, $soft-bg-level) !important;
  // color: $primary !important;
  border: 1px solid shift-color($primary, $soft-bg-level) !important;
}

button.dt-button:hover:not(.disabled) {
  background: shift-color($primary, $soft-bg-level) !important;
  border: 1px solid shift-color($primary, $soft-bg-level) !important;
  color: $primary !important;
}
