/**  =====================
      Data Tables css start
==========================  **/

.table {
  td,
  th {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    white-space: nowrap;
    // padding: 1.05rem 0.75rem;
    padding: 0.7rem 0.75rem;
  }

  thead th {
    border-bottom: 1px solid $border-color;
    font-size: 13px;
    color: $headings-color;
    background: transparentize($body-bg, 0.5);
    text-transform: uppercase;
  }

  tbody + tbody {
    border-top: 2px solid $border-color;
  }

  &.table-dark {
    thead th {
      background-color: #11171a;
    }
  }

  &.dataTable[class*='table-'] {
    thead th {
      background: $body-bg;
    }
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $border-color;
  }

  > :not(:first-child) {
    border-top: none;
  }
}
.custom-select,
.dataTable-selector,
.dataTable-input {
  @extend .form-control;
}
/* Border versions */
.table-bordered {
  border: 1px solid $border-color;

  td,
  th {
    border: 1px solid $border-color;
  }
}

/* Zebra-striping */
.table-striped {
  tbody tr:nth-of-type(2n + 1) {
    background-color: transparentize($primary, 0.97);
  }
}

/* Hover effect */
.table-hover {
  tbody tr {
    &:hover {
      background-color: transparentize($primary, 0.97);
    }
  }
}

/* Table backgrounds */
.table {
  .thead-dark {
    th {
      color: #fff;
      background-color: $dark;
      border-color: darken($dark, 10%);
    }
  }
}

.table-dark {
  color: #fff;
  background-color: $dark;

  td,
  th,
  thead th {
    border-color: darken($dark, 10%);
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: darken($dark, 2%);
    }
  }

  &.table-hover {
    tbody tr {
      &:hover {
        background-color: darken($dark, 5%);
      }
    }
  }

  thead th {
    // background-color: $dark;
    color: #fff;
  }
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: calc(#{$header-height} + 55px) !important;

  @media screen and (max-width: 992px) {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}
/**======= basic table css start =======**/

.table.table-xl {
  td,
  th {
    padding: 1.25rem 0.8rem;
  }
}

.table.table-lg {
  td,
  th {
    padding: 0.9rem 0.8rem;
  }
}

.table.table-de {
  td,
  th {
    padding: 0.75rem 0.8rem;
  }
}

.table.table-sm {
  td,
  th {
    padding: 0.6rem 0.8rem;
  }
}

.table.table-xs {
  td,
  th {
    padding: 0.4rem 0.8rem;
  }
}

/**======= basic table css end ======**/

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  top: 13px;
}

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  margin-top: -21px;
  margin-left: -7px;
}

// vanilla table css start

.dataTable-top {
  padding: 4px 4px 14px;
}
.dataTable-bottom {
  padding: 14px 4px 4px;
}
.card.table-card {
  .dataTable-top {
    padding-top: var(--bs-card-spacer-y);
  }
  .dataTable-top,
  .dataTable-bottom {
    padding-left: var(--bs-card-spacer-x);
    padding-right: var(--bs-card-spacer-x);
  }
}
.dataTable-top > {
  nav:first-child,
  div:first-child {
    float: left;
  }
}

.dataTable-bottom > {
  nav:first-child,
  div:first-child {
    float: left;
  }
}

.dataTable-top > {
  nav:last-child,
  div:last-child {
    float: right;
  }
}

.dataTable-bottom > {
  nav:last-child,
  div:last-child {
    float: right;
  }
}
.dataTable-dropdown {
  label {
    display: flex;
    align-items: center;
    width: 200px;
    white-space: nowrap;
    select {
      margin-right: 8px;
    }
  }
}
.dataTable-info {
  margin: 7px 0;
}

/* PAGER */
.dataTable-pagination {
  ul {
    margin: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
    float: left;
  }

  a {
    border: 1px solid transparent;
    float: left;
    margin-left: 2px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
    border-radius: $border-radius;
    color: $body-color;

    &:hover {
      background-color: shift-color($primary, $soft-bg-level);
      color: $primary;
    }
  }

  .active a {
    background-color: $primary;
    color: #fff;
    cursor: default;

    &:focus,
    &:hover {
      background-color: $primary;
      color: #fff;
      cursor: default;
    }
  }

  .ellipsis a {
    cursor: not-allowed;
  }

  .disabled a {
    cursor: not-allowed;

    &:focus,
    &:hover {
      cursor: not-allowed;
    }

    cursor: not-allowed;
    opacity: 0.4;

    &:focus,
    &:hover {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  .pager a {
    font-weight: bold;
  }
}
/* TABLE */
.dataTable-table {
  > {
    tbody > tr > {
      td,
      th {
        vertical-align: middle;
      }
    }

    tfoot > tr > {
      td,
      th {
        vertical-align: middle;
      }
    }

    thead > tr > {
      td {
        vertical-align: middle;
      }

      th {
        vertical-align: middle;
      }
    }
  }

  th {
    vertical-align: middle;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    right: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    opacity: 0.2;
  }

  &::before {
    border-top: 4px solid #000;
    bottom: 0px;
  }

  &::after {
    border-bottom: 4px solid #000;
    border-top: 4px solid transparent;
    top: 0px;
  }
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
  clear: both;
  content: ' ';
  display: table;
}
// vanilla table css end
// data table css start
table.dataTable {
  border-collapse: collapse !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent;
}
.dataTables_length {
  select.form-select {
    padding-right: 30px;
  }
}
// @media screen and (max-width: 767px) {
// }
.dataTables_length,
.dataTables_filter {
  padding: 0px 25px 14px;
}

.dataTables_wrapper .dataTables_info {
  padding-top: 20px !important;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 1.25em !important;
  padding-bottom: 10px !important;
}
// data table css end

/**====== Data Tables css end ======**/
