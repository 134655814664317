// ============================
//     Badge css start
// ============================

.badge {
  @each $color, $value in $theme-colors {
    &.bg-light-#{$color} {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }
}
