app-form-advance .form-group label {
  color: #666;
}
.form-check .form-check-input.input-primary:checked {
  border-color: $primary;
  background-color: $primary;
}
.form-check .form-check-input.input-secondary:checked {
  border-color: $secondary;
  background-color: $secondary;
}
.form-check .form-check-input.input-success:checked {
  border-color: $success;
  background-color: $success;
}
.form-check .form-check-input.input-danger:checked {
  border-color: $danger;
  background-color: $danger;
}
.form-check .form-check-input.input-warning:checked {
  border-color: $warning;
  background-color: $warning;
}
.form-check .form-check-input.input-info:checked {
  border-color: $info;
  background-color: $info;
}
.form-check .form-check-input.input-dark:checked {
  border-color: $dark;
  background-color: $dark;
}

.form-check .form-check-input.input-light-primary:checked {
  border-color: $light-primary;
  background-color: $light-primary;
}
.form-check .form-check-input.input-light-secondary:checked {
  border-color: $light-secondary;
  background-color: $light-secondary;
}
.form-check .form-check-input.input-light-success:checked {
  border-color: $light-success;
  background-color: $light-success;
}
.form-check .form-check-input.input-light-danger:checked {
  border-color: $light-danger;
  background-color: $light-danger;
}
.form-check .form-check-input.input-light-warning:checked {
  border-color: $light-warning;
  background-color: $light-warning;
}
.form-check .form-check-input.input-light-info:checked {
  border-color: $light-info;
  background-color: $light-info;
}
.form-check .form-check-input.input-light-dark:checked {
  border-color: $light-dark;
  background-color: $light-dark;
}

.form-check .form-check-input.input-light-primary:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMDRhOWY1JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-secondary:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjNzQ4ODkyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-success:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMWRlOWI2JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-danger:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZjQ0MjM2JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-warning:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZjRjMjJiJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-info:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjM2ViZmVhJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
.form-check .form-check-input.input-light-dark:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzc0NzRmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
}
