$black: #000 !default;
$spacer: 1rem !default;
$spacer1: 16px !default;
// $gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

$header-height: 3.5rem !default;
$header-footer-height: ($header-height * 2) !default;
$enable-rounded: true !default;
$border-radius: 0.125rem !default;
$border-radius-lg: 0.25rem !default;
$border-radius-sm: 0.0625rem !default;
$font-family-headers: 'Montserrat', Helvetica, sans-serif !default;

$card-border-radius: 0.125rem !default;
$card-spacer-y: 1rem !default;
$card-border-width: 0 !default;
$card-border-color: rgba($black, 0.06) !default;
$card-cap-bg: white !default;

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

.taskboard {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /*height: calc(100vh - #{$header-height});*/
  padding: $spacer;
  .taskboard-wrapper {
    width: 280px;
    padding-right: ($spacer * 0.5);
    padding-left: ($spacer * 0.5);
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .taskboard-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    white-space: normal;
    border-radius: $border-radius;
    background: rgba(var(--bs-primary-rgb), 0.175);
  }
  .taskboard-header {
    padding: 16px;
    display: flex;
    align-items: center;
    padding: 16px;
    .form-control {
      &:not(:focus) {
        border-color: transparent;
        background: transparent;
        box-shadow: none;
      }
    }
  }
  .taskboard-header {
    position: relative;
    /*display: flex;*/
    text-align: center;
    flex-direction: row;
    align-items: center;
    font-family: $font-family-headers;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 18px;
    font-weight: 700;
  }
  .taskboard-task {
    position: relative;
    display: flex;
    flex-direction: column;

    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);
    padding: $spacer1;
    margin-bottom: ($spacer * 1.5);

    color: $black;
    font-weight: 900;
    background: #fff;

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      cursor: grab;
    }
    &.gu-transit {
      opacity: 0.2;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
      filter: alpha(opacity=20);
      background: #fff;
    }
  }
  .taskboard-task:after {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    top: 0;
    border-top-width: 12px;
    border-bottom-color: transparent;
    right: 6px;
  }
  .taskboard-task .card-body {
    background: $white;
    padding: 16px;
  }
  .taskboard-task.task-status-success:after {
    border-top-color: $success;
    border-right-color: $success;
    border-left-color: $success;
  }
  .taskboard-task.task-status-info:after {
    border-top-color: $info;
    border-right-color: $info;
    border-left-color: $info;
  }
  .taskboard-task.task-status-warning:after {
    border-top-color: $warning;
    border-right-color: $warning;
    border-left-color: $warning;
  }
  .taskboard-task.task-status-danger:after {
    border-top-color: $danger;
    border-right-color: $danger;
    border-left-color: $danger;
  }
  .taskboard-cards {
    padding: 0 16px 16px;
    box-sizing: border-box;
    display: block;
    /*overflow-x: hidden;
    overflow-y: auto;*/
  }
  .taskboard-task-title {
    margin-bottom: ($spacer * 0.5);
  }
}

.pc-kanban-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 6px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: darken($body-bg, 05%);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($body-bg, 25%);
  }
  .pc-kanban-column {
    background: rgba(var(--bs-primary-rgb), 0.175);
    border-radius: $border-radius;
    width: 250px;
    flex: 0 0 auto;
    max-width: 100%;
    margin-bottom: 12px;
    &:not(:last-child) {
      margin-right: var(--bs-gutter-x);
    }
    .card {
      margin-bottom: 16px;
      .card-body {
        padding: 16px;
      }
    }
  }
  .pc-kanban-cards {
    padding: 16px 0;
  }
  .pc-kanban-header {
    display: flex;
    align-items: center;
    padding: 16px;
    .form-control {
      &:not(:focus) {
        border-color: transparent;
        background: transparent;
        box-shadow: none;
      }
    }
  }
  .pc-kanban-body {
    padding: 0 16px 16px;
    height: calc(100vh - 480px);
  }
}
.btn-collpsed:not(.collapsed) {
  transform: rotate(90deg);
}
.tbl-backlog {
  tr:not(:hover) {
    .drp-suboption {
      opacity: 0;
    }
  }
}
