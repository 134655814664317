/**  =====================
      mail css start
==========================  **/

.choose-color {
  border: none;
}
.text-color-pick {
  border: none;
  background: none;
}

.btn.btn-muted {
  background-color: $gray-200;
  color: $sidebar-caption;
  margin-left: 0px !important;
}
