@import 'bar-rating';
@import 'data-tables';
@import 'daterange';
@import 'lightbox';
@import 'maxlength';
@import 'sweatalert';
@import 'notification';
@import 'pnotify';
@import 'modal';
@import 'rangeslider';
@import 'tags-input';
@import 'wizard';
@import 'select2';
@import 'slider';
@import 'switches';
@import 'peitychart';
@import 'tree-view';
@import 'ngx-chips';
@import 'angular-datatables';

.switch {
  border: none !important;
}

.utility-card label {
  margin-right: 3px;
  border-radius: 6px;
}

.auth-main .carousel .carousel-indicators {
  bottom: -30px;
}

app-profile-one app-card .card,
app-profile-three app-card .card {
  border: 1px solid #eeeeee !important;
}

.mat-vertical-stepper-header .mat-step-icon {
  display: none;
}

.mat-vertical-stepper-header {
  margin-bottom: 10px;
}

.mat-step-text-label .flex-shrink-0:hover {
  color: '$pc-primary';
}

app-chat {
  .nav {
    display: block;
  }
  .nav-tabs .nav-item:not(:last-child) .nav-link {
    border-bottom: 1px solid $border-color;
  }
}

dp-day-calendar .dp-weekdays {
  font-size: 13px !important;
}

dp-day-calendar.dp-material .dp-current-day {
  border: none !important;
}

.berry-dark {
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: $white;
  }
}

.m-24 {
  margin-bottom: 24px;
  border-radius: 8px;
}

app-email .nav {
  display: block !important;
}

app-form-basic .one-dropdown {
  padding: 0.65rem 1rem;
}

// third party table
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: $primary !important;
}
.dataTables_filter label input {
  margin-left: 10px !important;
}

table.dataTable {
  width: 100% !important;
}

// kanban app
app-kanban .ng-scroll-content {
  display: block !important;
}

// configuration app
app-configuration {
  .nav-tabs .cut-tab.active {
    color: $primary;
  }
  .form-group label {
    margin-bottom: 0.2rem !important;
  }
}

.tag-chips .ng2-tag-input__text-input {
  padding: 0.3rem 0.5rem 0rem 0.5rem !important;
}

.switch.switch-medium {
  margin: 0 0 10px 0 !important;
}

.dropdown-menu {
  z-index: 1030 !important;
}

dp-date-picker {
  display: block !important;

  &.dp-material .dp-picker-input {
    width: 100% !important;
  }
}

.berry-rtl {
  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after,
  aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after {
    left: calc(50% - 115px);
  }

  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after {
    left: calc(50% - 98px);
  }

  aw-wizard-navigation-bar.vertical.small ul.steps-indicator {
    padding: 5px 19px 5px 75px;
  }

  aw-wizard-navigation-bar.vertical ul.steps-indicator li a {
    display: block;
  }
}

// calender app
.colorpick-eyedropper-input-trigger {
  display: none !important;
}

// ng select
.ng-select .ng-arrow-wrapper .ng-arrow {
  transform: rotate(180deg) !important;
}

.ng-select.ng-select-clearable .ng-select-container.ng-has-value:hover .ng-clear-wrapper {
  opacity: 0 !important;
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 10000;
}

// text-editor
@media (max-width: 540px) {
  .NgxEditor__MenuBar {
    position: relative;
  }
  .NgxEditor__MenuItem {
    position: static !important;
  }
  .NgxEditor__Popup {
    left: 0px;
    right: 0;
    top: 100% !important;
  }
}
